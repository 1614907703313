<template>
  <form @submit.prevent="handleSubmit" class="base-form h-100 d-flex justify-content-between flex-column">
    <div class="card card-form">
      <div class="card-header">
        <div class="card-title">
          General
        </div>
      </div>
      <div class="card-body">
        <div class="cmn-fw">
          <div class="input-group _group-xl mb-3">
            <span class="input-group-text prepend">
              Country<span class="required">*</span>
            </span>
            <select v-model="form.country" class="form-select" required>
              <option value="" selected disabled>Select</option>
              <option v-for="option in country_options" :value="option.name" :key="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <div class="input-group _group-xl mb-3">
            <span class="input-group-text prepend">
              Language<span class="required">*</span>
            </span>
            <select v-model="form.language" class="form-select" required>
              <option selected="selected" disabled value="">Select</option>
              <option value="en">English</option>
              <option value="fr">French</option>
              <option value="de">German</option>
              <option value="it">Italian</option>
              <option value="es">Spanish</option>
            </select>
          </div>
          <div class="input-group _group-xl mb-3">
            <span class="input-group-text prepend">
              Currency<span class="required">*</span>
            </span>
            <select v-model="form.currency" class="form-select" required>
              <option value="" selected disabled>Select</option>
              <option v-for="option in currency_options" :value="option.id" :key="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-footer d-flex align-items-end">
          <button type="submit" role="button" class="btn btn-success" @click="saveData">
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { COUNTRIES_LIST, CURRENCY_LIST } from "@/config/url";

export default {
  name: "GeneralComponent",
  data() {
    return {
      loadingInstance: null,
      countries: null,
      currencies: null,
      form: {
        country: "",
        currency: "",
        language: "",
      },
      country: "",
      currency: "",
      language: "",
    };
  },
  async mounted() {
    await Promise.all([this.fetchCountries(), this.fetchCurrencies()]);
    this.setFormData();
  },
  computed: {
    country_options() {
      if (this.countries) {
        return this.countries.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
    currency_options() {
      if (this.currencies) {
        return this.currencies.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    handleClick() { },
    async fetchCountries() {
      this.countries = await this.axios
        .get(COUNTRIES_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchCurrencies() {
      this.currencies = await this.axios
        .get(CURRENCY_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setFormData() {
      let foundCountry = this.country_options.find((country) => {
        return country.id == this.$store.getters["auth/getUserData"].country_id;
      });

      if (foundCountry) {
        this.country = this.form.country = foundCountry.name;
      }
      this.currency = this.form.currency =
        this.$store.getters["auth/getUserData"].currency_id;
      this.language = this.form.language =
        this.$store.getters["auth/getUserData"].language;
    },
    saveData() {
      this.showLoading();

      let form_data_language = new FormData();
      let form_data_country = new FormData();
      let form_data_currency = new FormData();

      form_data_country.append("countryname", this.form.country);
      form_data_language.append("language", this.form.language);
      form_data_currency.append("currency_id", this.form.currency);

      const requests = [];

      if (this.form.country !== this.country) {
        requests.push(
          this.$store.dispatch("auth/updateCountry", form_data_country)
        );
      }
      if (this.form.language !== this.language) {
        requests.push(
          this.$store.dispatch("auth/updateLanguage", form_data_language)
        );
      }
      if (this.form.currency !== this.currency) {
        requests.push(
          this.$store.dispatch("auth/updateCurrency", form_data_currency)
        );
      }
      if (
        this.form.country !== this.country ||
        this.form.language !== this.language ||
        this.form.currency !== this.currency
      ) {
        requests.push(this.$store.dispatch("auth/refreshProfile"));
      }
      Promise.all(requests)
        .then((results) => {
          results.forEach((res) => {
            if (res.status === 1) {
              this.$toast.success("Save data Successful.");
            } else {
              this.$toast.error(res.message);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style scoped></style>