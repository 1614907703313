import quotationService from "../services/quotation.service";

const initialState = {
  list_quotation: [],
};

export const quotation = {
  namespaced: true,
  state: initialState,
  getters: {
    getQuotation: (state) => {
      return state.list_quotation;
    },
    getQuotationEditData:
      ({ quotationById }) =>
      (id) =>
        quotationById[id],
  },
  actions: {
    getList({ commit }, quotation) {
      return quotationService
        .getList(quotation)
        .then((response) => {
          commit("listQuotation", response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    searchList({ commit }, quotation) {
      return quotationService
        .searchList(quotation)
        .then((response) => {
          // commit('listQuotation', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getQuotationNumber({ commit }, quotation) {
      return quotationService
        .getQuotationNumber(quotation)
        .then((response) => {
          // commit('listQuotation', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getQuotationByNumber({ commit }, quotation) {
      return quotationService
        .getQuotationByNumber(quotation)
        .then((response) => {
          // commit('listQuotation', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    saveQuotation({ commit }, quotation) {
      return quotationService
        .saveQuotation(quotation)
        .then((response) => {
          // commit('listQuotation', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    saveQuotationAttachment({ commit }, quotation) {
      return quotationService
        .saveQuotationAttachment(quotation)
        .then((response) => {
          // commit('listQuotation', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    saveEditQuotation({ commit }, quotation) {
      return quotationService
        .saveEditQuotation(quotation)
        .then((response) => {
          // commit('listQuotation', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    deleteQuotation({ commit }, invoice) {
      return quotationService
        .deleteQuotation(invoice)
        .then((response) => {
          // commit('listinvoice', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    deleteQuotationAttachment({ commit }, invoice) {
      return quotationService
        .deleteQuotationAttachment(invoice)
        .then((response) => {
          // commit('listinvoice', response);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    sendEmail({ commit }, quotation) {
      return quotationService
        .sendEmail(quotation)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getQuotationByNumber({ commit }, quotation) {
      return quotationService
        .getQuotationByNumber(quotation)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    save(state, quotation) {
      Object.assign(state, quotation);
    },
    listQuotation(state, quotation) {
      state.list_quotation = quotation.data.response;
      state.quotationById = quotation.data.response.reduce((acc, quotation) => {
        acc[quotation.id] = quotation;
        return acc;
      }, {});
    },
  },
};
