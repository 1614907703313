<template>
  <div>
    <div :class="{ 'card card-general': fromView !== 'add_invoice' }">
      <div
        :class="{
          'card-body': fromView !== 'add_invoice',
          'pe-5 ps-5': fromView == 'add_invoice',
        }"
      >
        <form class="form-v2 h-100 d-flex flex-column justify-content-between">
          <div class="w-100">
            <div class="row mb-2">
              <div class="form-check form-switch col-6">
                <input
                  class="form-check-input parts"
                  type="radio"
                  id="parts"
                  value="parts"
                  v-model="item_form.type"
                />
                <label
                  class="form-check-label"
                  :for="item_form.type === 'parts' ? 'parts' : 'service'"
                  >Parts</label
                >
              </div>
              <div class="form-check form-switch ms-5 col-6">
                <input
                  class="form-check-input services"
                  type="radio"
                  id="services"
                  value="service"
                  v-model="item_form.type"
                />
                <label
                  class="form-check-label"
                  :for="item_form.type === 'service' ? 'service' : 'parts'"
                  >Services</label
                >
              </div>
            </div>

            <div class="row mb-3">
              <div class="input-group p-0">
                <div class="input-group-text prepend">
                  <img src="@/assets/images/system/tools_lauren-green.svg" />
                </div>
                <input
                  type="text"
                  name="itemTitle"
                  class="form-control"
                  v-model="item_form.name"
                  placeholder="Type item name or title*"
                />
                <div class="input-group-text transparent p-0 append">
                  <button
                    type="button"
                    name="btn"
                    class="btn btn-white _sm"
                    @click="$emit('show-list-of-items')"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <textarea
                class="form-control"
                v-model="item_form.description"
                placeholder="Parts or Services description"
              ></textarea>
            </div>
          </div>

          <div class="w-100">
            <div class="row m-0 mb-3 d-flex justify-content-end">
              <div class="col-sm-9">
                <div class="row mb-3">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-light-orange prepend no-after">
                      Quantity
                    </div>
                    <div class="form-floating">
                      <label class="floating-label _sm _end">(default=1)</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        @blur="calculateAmount"
                        v-model.number="item_form.quantity"
                        min="1"
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-light-sky no-after prepend">Rate</div>
                    <div class="form-floating">
                      <label class="floating-label">&pound;</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        v-model.number="item_form.unit_price"
                        @blur="calculateAmount"
                        step="0.01"
                        min="0.00"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-orange no-after prepend">Amount</div>
                    <div class="form-floating">
                      <label class="floating-label">&pound;</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        v-model="item_form.amount"
                        step="0.01"
                        min="0.00"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row m-0 d-flex justify-content-end">
          <div class="cmn-aw p-0">
            <button
              type="submit"
              name="save"
              class="btn btn-success btn-big _3d-input"
              @click="createItem"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddSingleItem",
  props: ["fromView"],
  data() {
    return {
      item_form: {
        type: "parts",
        name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        amount: 0,
      },
      dialogVisibleCategoryPopup: false,
    };
  },
  created() {
    this.$store.commit("item/clearToRecentItems");
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    calculateAmount() {
      this.item_form.amount = (
        this.item_form.quantity * parseFloat(this.item_form.unit_price)
      ).toFixed(2);
    },
    createItem() {
      if (
        this.item_form.name.trim() === "" ||
        this.item_form.quantity <= 0 ||
        this.item_form.unit_price < 0 ||
        this.item_form.amount <= 0
      ) {
        this.$toast.error("Form is invalid. Please fix the errors.");
        return; // Prevent form submission if validation fails
      }
      
      this.$store.commit("item/addToRecentItems", this.item_form);
      this.$nextTick(() => {
        this.item_form = {
          type: "parts",
          name: "",
          description: "",
          quantity: 1,
          unit_price: 0,
          amount: 0,
        };
        this.$emit("closeItemsPopup", true);
      });
    },
    resetForm() {
      this.item_form = {
        type: "parts",
        name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        amount: 0,
      };
    },
    setAddedItemNumber() {
      this.listAddedItemNumber =
        JSON.parse(localStorage.getItem("item_recently_added_items")) || [];
    },
  },
};
</script>
