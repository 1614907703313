<template>
  <div class="">
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div v-if="limitExpireCheck">
          <div class="alert alert-danger" role="alert">
            Limit reached, Please subscribe.
          </div>
        </div>
        <div class="row justify-content-end mx-0">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-end">
            <!-- <button class="btn btn-white">Expense No: 0123456789</button> -->
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="row mx-0">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
            <form class="base-form">
              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <span class="input-group-text prepend bg-bostonBlue flush-after">
                    Payment Method
                    <span class="required">*</span>
                  </span>
                  <select v-model="form.payment_type" required class="form-select">
                    <option disabled selected value="">Payment Method*</option>
                    <option v-for="option in payment_method" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <span class="input-group-text prepend bg-bostonBlue flush-after">
                    Expense Date
                  </span>
                  <input type="date" name="datepick" class="form-control datepicker" v-model="form.expense_date"
                    onfocus="this.showPicker()" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <span class="input-group-text prepend bg-bostonBlue flush-after">
                    Currency
                  </span>
                  <select v-model="form.currency_id" required class="form-select" @change="changeCurrency">
                    <option disabled selected value="">Currency*</option>
                    <option v-for="option in currencyOptions" :value="option.id" :key="option.id">
                      {{ option.name }} ({{ option.symbol }})
                    </option>
                  </select>

                  <!-- <select name="currency" class="form-select" required>
                                        <option value="" selected disabled>Please Select</option>
                                        <option value="">British Pound</option>
                                        <option value="">American Dollar</option>
                                        <option value="">Euro</option>
                                    </select> -->
                </div>
              </div>

              <div class="row m-0 mb-3 mt-5">
                <div class="input-group _group-xl p-0">
                  <select v-model="form.category_id" required class="form-select">
                    <option disabled selected value="">Expense category*</option>
                    <option v-for="option in expenseCategoryServer" :value="option.id" :key="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <input type="text" name="vendorName" class="form-control" placeholder="Name of the Vendor (Optional)"
                    v-model="form.vendor" />
                </div>
              </div>

              <div class="row m-0 mb-4">
                <div class="input-group _group-xl h-auto p-0">
                  <textarea name="notes" class="form-control textarea-lg no-resize" placeholder="Notes..."
                    v-model="form.notes"></textarea>
                </div>
                <span class="form-text text-end text-stamp">Max 255 character</span>
              </div>

              <div class="row m-0 mb-3 mt-5 d-flex justify-content-end">
                <div class="col-sm-9">
                  <div class="row">
                    <div class="input-group input-group--3d group-half p-0">
                      <div class="input-group-text bg-orange no-after prepend">
                        Amount Paid
                      </div>
                      <div class="form-floating">
                        <label class="floating-label">{{ currency_symbol }}</label>
                        <input class="form-control text-right" type="text" v-model="form.amount"
                          placeholder="10,000.00" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 mx-auto">
            <el-carousel :interval="2000">
              <el-carousel-item v-for="(image_file, key) in image_list" :key="key">
                <img class="preview" :ref="'image' + parseInt(key)" />
              </el-carousel-item>
            </el-carousel>

            <div class="row">
              <div class="col-sm-12 d-flex align-items-center justify-content-center">
                <h6>Preview ({{ image_list.length }})</h6>
              </div>
            </div>

            <div class="row mt-2rem">
              <div class="col-sm-12 d-flex align-items-center justify-content-center">
                <div class="custome-fileupload-circle">
                  <input type="file" class="file-input expense-file" accept="image/jpeg, image/png" @change="onFileChange"
                    multiple="multiple" />
                  <div class="file-content">
                    <div class="file-icon">
                      <i class="fa fa-camera"></i>
                    </div>
                    <div class="file-info-text">Add Attachment</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 d-flex align-items-center justify-content-center mt-2">
                <small>Note: Max 1 image, size: 512kb, png or jpeg only.</small>
              </div>
            </div>

            <div class="row mt-2rem">
              <div class="col-sm-12 d-flex align-items-center justify-content-between">
                <a href="/list-expenses" class="btn btn-default btn-shadow">Cancle</a>
                <button v-if="!limitExpireCheck" class="btn btn-success btn-shadow" @click="saveData">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CURRENCY_LIST } from "../../config/url";
import moment from "moment";

export default {
  name: "AddExpenseComponent",

  data() {
    return {
      loadingInstance: null,
      currency: null,
      payment_method: null,
      expense_category: null,
      form: {
        amount: "",
        payment_type: "",
        expense_date: "",
        category_id: "",
        vendor: "",
        notes: "",
        currency_id: this.$store.state.auth.user.currency_id,
        image: [],
        is_image: "N",
      },
      image_list: [],
      image_count: 0,
      currency_symbol: "$",
      getUserSubscriptionExpire: true,
      NoOFDaysFreeUsage: true,
      limitExpireCheck: false,
      getExpenseSubscriptionExpire: 0,
    };
  },
  mounted() {
    // Array to store promises from asynchronous functions
    const fetchPromises = [
      this.fetchCurrency(),
      this.fetchPaymentMethod(),
      this.expenseCategory(),
      this.getLimitForExpenses()
    ];

    // Wait for all promises to resolve
    Promise.all(fetchPromises)
      .then(() => {
        this.getUserSubscriptionExpire = this.$store.getters["auth/getUserSubscriptionExpire"];
        this.NoOFDaysFreeUsage = this.$store.getters["auth/getUserNoOFDays"];

        if (this.getUserSubscriptionExpire && this.NoOFDaysFreeUsage && this.getExpenseSubscriptionExpire > 5) {
          this.limitExpireCheck = true;
          this.$toast.error("Monthly limit reached.");
        }

        // Call changeCurrency() after all data is fetched
        this.changeCurrency();
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle errors if needed
      });
  },
  computed: {
    currencyOptions() {
      if (this.currency) {
        return this.currency.map((v) => {
          return {
            id: v.id,
            name: v.name,
            symbol: v.symbol,
          };
        });
      }
      return [];
    },
    expenseCategoryServer() {
      if (this.expense_category) {
        return this.expense_category.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    saveData() {
      if (this.limitExpireCheck) {
        this.$toast.error("Monthly limit reached.");
        return false;
      }
      this.showLoading();

      this.form.expense_date = moment(this.expense_date).format("YYYY-MM-DD 00:00:00");
      this.form.image = [];
      let form_data = new FormData();
      this.image_list.forEach((image) => {
        form_data.append("image[]", image);
      });
      
      form_data.append("amount", this.form.amount);
      form_data.append("payment_type", this.form.payment_type);
      form_data.append("expense_date", this.form.expense_date);
      form_data.append("category_id", this.form.category_id);
      form_data.append("vendor", this.form.vendor);
      form_data.append("notes", this.form.notes);
      form_data.append("currency_id", this.form.currency_id);
      form_data.append("is_image", this.form.is_image);

      this.$store
        .dispatch("expenses/saveExpenses", form_data)
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.$toast.success("Save data Successful.");
            this.$router.push({ name: "list-expenses" });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async fetchCurrency() {
      this.currency = await this.axios
        .get(CURRENCY_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async expenseCategory() {
      this.showLoading();
      this.expense_category = await this.$store
        .dispatch("expenses/getAllExpenseCategory")
        .then((res) => {
          if (res.status == 1) {
            return res.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try againddd.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async fetchPaymentMethod() {
      this.showLoading();
      this.payment_method = await this.$store
        .dispatch("expenses/getPaymentOptions")
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            return res.response;
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    onFileChange(e) {
      // let vm = this;
      this.image_list = new Array(...e.target.files);
      const files = e.target.files;
      const maxSize = 512 * 1024; // 512 KB in bytes

      for (let i = 0; i < this.image_list.length; i++) {
        const file = files[i];
        if (file.size > maxSize) {
          this.$toast.error(`File "${file.name}" exceeds the maximum allowed size of 512 KB.`);
          // Optionally clear the input or handle the error in your application
          e.target.value = null;
          break;
        }

        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener(
          "load",
          function () {
            this.$refs["image" + parseInt(i)][0].src = reader.result;
          }.bind(this),
          false
        ); //add event listener

        reader.readAsDataURL(this.image_list[i]);
        if (this.image_list.length > 0) {
          this.image_count = this.image_list.length;
          this.form.is_image = "Y";
        }
      }

      this.image_list = this.image_list.filter(element => element.size <= (512 * 1024))
    },
    changeCurrency() {
      this.currency_symbol = this.currency.find(
        (currency) => currency.id === this.form.currency_id
      ).symbol;
    },
    getLimitForExpenses() {
      this.$store
        .dispatch("expenses/getExpensesLimit")
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.getExpenseSubscriptionExpire = res.response.expense_count;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style scoped>
.el-carousel {
  border: 2px solid #ccc;
  padding: 10px;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 2px solid #fff;
}
</style>
