<template>
  <div>
    <section class="">
      <div class="bg-pattern overlay-signUp logReg-container">
        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
          <!--login form block start-->
          <div class="card card-form sign-upIn">
            <div class="card-header">
              <div class="card-title">Sign up</div>
            </div>
            <div class="card-body">
              <form @submit.prevent="handleSubmit" class="base-form">
                <!-- <input type="hidden" name="app_type" value="SmoothBalance">
                                <input type="hidden" name="user_type" value="web"> -->
                <h5 class="form-title">User sign up details</h5>
                <div class="row row-cols-md-1 row-cols-lg-2 g-3">
                  <div class="col col-lg-7">
                    <div class="input-group _ig-transparent mb-3">
                      <span class="input-group-text"><i class="fa fa-user"></i> </span>
                      <input
                        type="text"
                        :model="first_name"
                        id="uf_name"
                        class="form-control"
                        placeholder="First name"
                        required
                      />
                    </div>
                  </div>
                  <div class="col col-lg-5 mb-3">
                    <input
                      type="text"
                      v-model="last_name"
                      name="ul_name"
                      class="form-control"
                      placeholder="Last name"
                      required
                    />
                  </div>
                </div>

                <div class="input-group _ig-transparent mb-3">
                  <span class="input-group-text">
                    <i class="far fa-envelope-open"></i>
                  </span>
                  <input
                    type="email"
                    v-model="form.email"
                    name="u_email"
                    class="form-control"
                    placeholder="Enter your email"
                    autocomplete="new-password"
                  />
                </div>
                <div class="input-group _ig-transparent mb-3">
                  <span class="input-group-text"><i class="fa fa-key"></i> </span
                  ><input
                    :type="showpassword ? 'text' : 'password'"
                    v-model="form.password"
                    name="u_password"
                    id="u_password"
                    class="form-control pw_toggle"
                    autocomplete="new-password"
                    placeholder="Password (minimum six character)"
                  />
                  <span
                    v-on:click="showpassword = !showpassword"
                    class="input-group-text"
                    id="toggle_pwd"
                    ><i class="fa fa-eye-slash"></i>
                  </span>
                </div>

                <div class="input-group _ig-transparent mb-1">
                  <span class="input-group-text"><i class="fa fa-lock"></i> </span>
                  <input
                    :type="showcpassword ? 'text' : 'password'"
                    v-model="confirm_password"
                    id="u_cpassword"
                    class="form-control"
                    placeholder="Confirm password"
                    required
                  />
                  <span
                    class="input-group-text"
                    v-on:click="showcpassword = !showcpassword"
                    id="toggle_cpwd"
                    ><i class="fa fa-eye-slash"></i>
                  </span>
                </div>
                <div
                  v-show="confirm_validate"
                  class="mb-2"
                  style="margin-bottom: 5px; font-size: 13px; color: red"
                >
                  Confirm password mismatched.
                </div>
                <!-- <div class="mb-2">
                  <div class="form-check form-switch onOff">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="checkSwitch"
                    />
                    <label class="form-check-label" for="checkSwitch"
                      >Remember me</label
                    >
                  </div>
                </div> -->

                <div class="form-footer">
                  <div class="row d-flex flex-row g-3 align-items-start">
                    <div class="col">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          required
                          v-model="valid_agreement"
                          type="checkbox"
                          id="termsagree"
                        />
                        <label class="form-check-label" for="termsagree">
                          By creating an account, you agree to Smoothbalance.com's
                          Conditions of Use,
                          <a href="https://smoothbalance.com/privacy-policy/"
                            >Privacy Notice and Cookies</a
                          >
                          and Internet Marketing.
                        </label>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        type="submit"
                        role="button"
                        class="btn btn-success"
                        :disabled="loading"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!--ended login form block-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Register",

  data() {
    return {
      loadingInstance: null,
      loading: false,
      showpassword: false,
      showcpassword: false,
      valid_agreement: false,
      confirm_password: "",
      confirm_validate: false,
      first_name: "",
      last_name: "",
      form: {
        fullname: "",
        email: "",
        password: "",
      },
      randomKey: Math.random(),
    };
  },
  watch: {
    confirm_password(val) {
      if (val === this.form.password && val != "") {
        this.confirm_validate = false;
      } else {
        this.confirm_validate = true;
      }
    },
  },
  computed: {
    pageTitle() {
      return (document.title = `${this.title.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      )} | Smoothbalance`);
    },
    fullname() {
      return this.first_name + " " + this.last_name;
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    handleSubmit() {
      if (!this.confirm_validate) {
        this.showLoading();
        this.form.fullname = this.fullname;
        let data = Object.assign(this.$store.state.register, this.form);
        this.$store
          .dispatch("auth/register", data)
          .then((res) => {
            if (res.status == 1) {
              this.$toast.success(res.message);
              setTimeout(() => {
                this.$router.push("login");
              }, 1000);
            } else {
              this.$toast.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    },
  },
  created() {},
};
</script>
