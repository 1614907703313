import ExpensesService from "../services/expenses.service";

const initialState = {
    list_expenses: []
}

export const expenses = {
    namespaced: true,
    state: initialState,
    getters: {
        getExpense: (state) => {
            return state.list_expenses;
        },
        getExpenseEditData: ({ expensesById }) => (id) => expensesById[id]
    },
    actions: {
        getList({ commit }, expenses) {
            return ExpensesService.getList(expenses)
                .then((response) => {
                    commit('listExpenses', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        getExpenseListById({ commit }, expenses) {
            return ExpensesService.getExpenseListById(expenses)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        generatePdf({ commit }, expenses) {
            return ExpensesService.generatePdf(expenses)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        generateZip({ commit }, expenses) {
            return ExpensesService.generateZip(expenses)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        generateExcel({ commit }, expenses) {
            return ExpensesService.generateExcel(expenses)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        async saveExpenses({ commit }, expenses) {
            try {
                const response = await ExpensesService.saveExpense(expenses);
                commit('save', response.data);
                return response.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        updateExpenses({ commit }, expenses) {
            return ExpensesService.updateExpense(expenses).then(
                response => {
                    // console.log(commit);
                    
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        deleteExpensesAttachment({ commit }, expenseData) {
            return ExpensesService.deleteExpensesAttachment(expenseData).then(
                response => {
                    commit('deleteAttachment', expenseData);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getPaymentOptions(payment_method) {
            return ExpensesService.getPaymentOptions(payment_method).then(
                response => {
                    
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getExpenseCategory({ commit }, expense_category) {
            return ExpensesService.getExpenseCategory(expense_category).then(
                response => {
                    
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllExpenseCategory({ commit }, expense_category) {
            return ExpensesService.getAllExpenseCategory(expense_category).then(
                response => {
                    
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        deleteExpense({ commit }, expense_id) {
            return ExpensesService.deleteExpense(expense_id).then(
                response => {
                    commit('deleteExpense', expense_id);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getUsersCurrency({ commit }, expense_id) {
            return ExpensesService.getUsersCurrency(expense_id).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getExpensesLimit({ commit }) {
            return ExpensesService.getExpensesLimit().then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        save(state, expenses) {
            Object.assign(state, expenses);
        },
        listExpenses(state, expenses) {
            state.list_expenses = expenses.data.response.data;
            state.expensesById = expenses.data.response.data.reduce((acc, expense) => {
                acc[expense.id] = expense;
                return acc;
            }, {});
            // localStorage.setItem('list-expenses', flatted.stringify(expenses.data.response));
        },
        deleteExpense(state, expense_id) {
            state.list_expenses = state.list_expenses.filter(expense => expense.id !== expense_id);
            delete state.expensesById[expense_id];
            // localStorage.setItem('list-expenses', flatted.stringify(state.list_expenses));
        },
        deleteAttachment(state, expenseData) {
            // const expense = state.expensesById[expenseData.expense_id];
            // const attachmentIndex = expense.attachments.findIndex(attachment => attachment.id === expenseData.attachment_id);
            // expense.attachments.splice(attachmentIndex, 1);
            // localStorage.setItem('list-expenses', flatted.stringify(state.list_expenses));
        }
    }
};