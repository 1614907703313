<template>
  <div class="base-form h-100 d-flex justify-content-between flex-column ">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <div class="card card-form _company" style="width:500px">
          <div class="card-header">
            <div class="card-title">
              Success
            </div>
          </div>
          <div class="card-body text-center">
            <div style="border-radius:200px; height:200px; width:200px; background: #d8dfc8; margin:0 auto;">
              <i class="checkmark">✓</i>
            </div>
            <h1>Success</h1>
            <p>You have successfully subscribed ti Smoothbalance.</p>
            <p>We'll keep you informed on product updates and special offers</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StripeSuccess",
  mounted() {
    this.successHandler();
  },
  methods: {
    successHandler() {
      let paymentId = localStorage.getItem('paymentId');
      let sessionId = this.$route.query.session_id
      const data = {
        app_type: "SmoothBalance",
        paymentId: paymentId,
        gateway: 'stripe',
        transactionCode: sessionId,
      };
      this.$store
          .dispatch("setting/successHandler", data)
          .then((res) => {
            if (res.data.status == 1) {
              localStorage.removeItem('paymentId');
            } else {
              this.$toast.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
    }
  }
};
</script>
<style scoped>


h1 {
  color: #008490;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

p {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

i {
  color: #008490;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

</style>