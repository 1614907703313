<template>
  <div class="dashboard-body-content d-flex justify-content-center align-items-center">
    <ul class="dashboard-quick-nav">
      <li class="nav-item">
        <a
          href="#"
          class="nav-link _quotation"
          @click="handleLinkClick('/add-quotation', $event)"
        >
          <span class="link-icon">
            <i class="fas fa-file-alt"></i>
            <span class="badge"><i class="fa fa-plus"></i></span>
          </span>
          <span class="link-text text-black"> Quotation </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#"
          class="nav-link _invoice"
          @click="handleLinkClick('/add-invoice', $event)"
        >
          <span class="link-icon">
            <i class="fas fa-file-alt"></i>
            <span class="badge"><i class="fa fa-plus"></i></span>
          </span>
          <span class="link-text text-black"> Invoice </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#"
          class="nav-link _expense"
          @click="handleLinkClick('/add-expense', $event)"
        >
          <span class="link-icon">
            <i class="fas fa-file-alt"></i>
            <span class="badge"><i class="fa fa-plus"></i></span>
          </span>
          <span class="link-text text-black"> Expense </span>
        </a>
      </li>
    </ul>

    <!-- Element Plus Dialog for showing registration warning -->
    <el-dialog v-model="dialogVisible" title="Complete Registration" width="500">
      <span
        >Your registration is not complete. Please complete your registration to
        proceed.</span
      >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <router-link
            style="display: initial"
            :to="{ name: 'soletrader-limitedcompany' }"
            class="el-button el-button--success"
          >
            Continue to Register
          </router-link>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {
      isProfileComplete: false,
      dialogVisible: false, // Controls the visibility of the Element Plus dialog
    };
  },
  created() {
    this.$store
      .dispatch("auth/refreshProfile")
      .catch((error) => console.error("Error refreshing profile:", error));

    this.$nextTick(() => {
      this.isProfileComplete = this.$store.getters["auth/getProfileCompleteStatus"];
    });
  },
  methods: {
    registrationCompleteModal() {
      this.dialogVisible = true; // Open the dialog
    },
    handleLinkClick(route, event) {
      if (!this.isProfileComplete) {
        // Prevent navigation if profile is incomplete
        event.preventDefault();
        // Show the modal
        this.dialogVisible = true;
      } else {
        // Manually navigate to the route if profile is complete
        // console.log("here is the route");

        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped></style>
