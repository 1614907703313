<template>
  <div>
    <!--dashboard main body start-->
    <div class="pt-0">
      <div class="-content d-flex align-items-start flex-column">
        <div class="page-global-header px-0">
          <div class="row">
            <div
              class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3"
            >
              <div class="global-searchbar">
                <form class="base-form" @submit.prevent="searchData">
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="@/assets/images/system/file-icon.svg" />
                    </div>
                    <input
                      type="search"
                      v-model="form.search_field"
                      name="globalSearch"
                      class="form-control"
                      placeholder="Search (Business name, client name, email, invoice number etc)"
                    />
                    <button type="submit" name="btnSearch" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </form>
                <div v-if="errorMessage">{{ errorMessage }}</div>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end"
            >
              <ul class="search-option-menu me-3">
                <li class="nav-item">
                  <a href="#" class="nav-link list-view">
                    <img src="@/assets/images/system/list-icon.png" />
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link grid-view">
                    <img src="@/assets/images/system/grid-icon.png" />
                  </a>
                </li>
                <li class="nav-item sorting">
                  <input
                    type="checkbox"
                    class="sorting-check"
                    id="btn-sorting"
                    autocomplete="off"
                    v-model="invoice_sorting_desc"
                    @change="changeOrder"
                  />
                  <label class="btn btn-sorting" for="btn-sorting"
                    ><span class="sr-only">Sort</span>
                  </label>
                </li>
                <li class="nav-item">
                  <a href="/add-invoice" class="nav-link circleNav"
                    ><i class="fa fa-plus"></i>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="fas fa-ellipsis-v"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          >PDF &nbsp;<i class="fa fa-envelope append"></i
                        ></el-dropdown-item>
                        <el-dropdown-item
                          >CSV &nbsp;<i class="fa fa-print append"></i
                        ></el-dropdown-item>
                        <el-dropdown-item
                          >Download Zip &nbsp;<i class="fa fa-download append"></i
                        ></el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </li>
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10 mx-auto mt-3">
              <div class="global-filter-form">
                <form class="base-form form-xl">
                  <div class="row g-2 d-flex justify-content-center">
                    <div class="col-sm-4">
                      <div class="form-floating">
                        <el-date-picker
                          v-model="form.start_date"
                          class="me-2"
                          format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD"
                        />
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="form-floating">
                        <el-date-picker
                          v-model="form.end_date"
                          class="me-2"
                          format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD"
                        />
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="form-floating">
                        <el-select v-model="form.category">
                          <el-option label="All" value="all"></el-option>
                          <el-option label="Paid" value="paid"></el-option>
                          <el-option label="Unpaid" value="unpaid"></el-option>
                          <el-option label="Unpaid due" value="unpaiddue"></el-option>
                          <el-option label="Part paid" value="partpaid"></el-option>
                          <el-option
                            label="Part paid due"
                            value="partpaiddue"
                          ></el-option>
                          <el-option label="Sent" value="sent"></el-option>
                          <el-option label="Draft" value="draft"></el-option>
                          <el-option label="Void" value="void"></el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="page-body">
          <div class="row justify-content-center">
            <!--repeatable block-->
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9">
              <div class="card-list-group">
                <!--repeatable block-->
                <div
                  class="card card-horizontal-list javaBlue"
                  v-for="(invoice, index) in invoice_server_data"
                  :key="invoice.invoice_id"
                >
                  <div class="card-body">
                    <div class="hover-slide">
                      <div class="form-check outliner">
                        <input class="form-check-input" type="checkbox" value="" />
                      </div>
                    </div>
                    <div class="card-content flex-shrink-0">
                      <span class="card-count">{{ index + 1 }}.</span>
                      <ul class="label-list">
                        <li class="list-title">{{ invoice.client.business_name }}</li>
                        <li class="sub-title">IN: {{ invoice.invoice_number }}</li>
                        <li class="text-danger">
                          D: {{ invoice.invoice_date }} - {{ invoice.expiry_date }}
                        </li>
                        <li>E: {{ invoice.client.email }}</li>
                      </ul>
                      <ul class="card-action-nav flex-start-horizontal mt-3">
                        <li class="nav-item" v-if="invoice.invoice_status == 'partpaid'">
                          <a href="#" class="btn btn-alert btn-sm"
                            ><i class="fa fa-dollar-sign"></i> P/Paid
                          </a>
                        </li>
                        <li class="nav-item" v-if="invoice.invoice_status == 'paid'">
                          <a href="#" class="btn btn-success btn-sm"
                            ><i class="fa fa-dollar-sign"></i> Paid
                          </a>
                        </li>
                        <li class="nav-item" v-if="invoice.invoice_status == 'draft'">
                          <a href="#" class="btn btn-azureBlue btn-sm"
                            ><i class="fa fa-dollar-sign"></i> Nill
                          </a>
                        </li>
                        <li class="nav-item" v-if="invoice.total_discount_amount > 0">
                          <a href="#" class="btn btn-secondary btn-sm"
                            ><i class="fa fa-percent"></i> Discount
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            href="#"
                            class="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#expenseFile"
                            @click="showDetails(invoice.invoice_number)"
                            ><i class="fa fa-paperclip"></i> File
                          </a>
                        </li>
                        <li class="nav-item ms-2">
                          <a href="#" class="btn btn-info btn-sm"
                            ><i class="fa fa-edit"></i> Note
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-footer has-action flex-shrink-1">
                    <div class="ellipsis-dropdown dropdown">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                          <i class="fas fa-ellipsis-v text-white"></i>
                        </span>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              :command="{ action: 'edit', id: invoice.invoice_number }"
                              ><i class="fa fa-edit"></i
                            ></el-dropdown-item>
                            <el-dropdown-item @click="voidData(invoice.invoice_id)"
                              ><i class="fa fa-times text-danger"></i
                            ></el-dropdown-item>
                            <!-- <el-dropdown-item
                            @click="
                              deleteData(invoice.invoice_id, invoice.client.client_id)
                            "
                            ><i class="fa fa-times"></i
                          ></el-dropdown-item> -->
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                    <ul class="log-status _sm center">
                      <li
                        class="list-item overdue"
                        v-if="
                          isNotExpired(invoice.expiry_date) &&
                          (invoice.invoice_status == 'draft' ||
                            invoice.invoice_status == 'partpaid')
                        "
                      >
                        <a href="#"><i class="fas fa-hourglass-end"></i> Overdue</a>
                      </li>
                      <li class="list-item draft" v-if="invoice.invoice_type == 'draft'">
                        <a href="#"><i class="fas fa-hourglass-end"></i> Draft</a>
                      </li>
                      <li class="list-item void" v-if="invoice.invoice_type == 'void'">
                        <a href="#"><i class="fas fa-hourglass-end"></i> Void</a>
                      </li>
                    </ul>
                    <div class="footer-list-wraper justify-content-end">
                      <ul class="footer-list has-border-bottom w-auto ps-2">
                        <li>Total</li>
                        <li class="count">&pound;{{ invoice.total_amount_gross }}</li>
                      </ul>

                      <ul class="footer-list highlight has-border-bottom w-auto ps-2">
                        <li>Paid</li>
                        <li class="count">-£{{ invoice.total_amount_paid }}</li>
                      </ul>

                      <ul class="footer-list _big w-auto ps-2">
                        <li>Balance Due</li>
                        <li class="count">
                          &pound;{{
                            isNaN(invoice.total_amount_gross - invoice.total_amount_paid)
                              ? 0
                              : invoice.total_amount_gross - invoice.total_amount_paid
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row mx-0 mt-5">
            <div
              class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3"
            >
              <div class="d-flex justify-content-end">
                <div class="card-totalCount card-totalCount--total">
                  <div class="card-header">Total</div>
                  <div class="card-body">&pound;100.00</div>
                </div>
                <div class="card-totalCount card-totalCount--paid ms-4">
                  <div class="card-header">Paid</div>
                  <div class="card-body">&pound;100.00</div>
                </div>

                <div class="card-totalCount card-totalCount--ballance ms-4">
                  <div class="card-header">Ballance Due</div>
                  <div class="card-body">&pound;100.00</div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!--dashboard main body end-->

    <!--Expense File Preview Modal-->
    <div
      class="modal fade mdl-messaging _info"
      id="expenseFile"
      aria-hidden="true"
      aria-labelledby="expenseFileLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Expense preview</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="message-card no-shadow m-1">
              <div class="abs-nav abs--tr me-n5">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :href="'/edit-invoice/' + popup_data.invoice_number"
                      ><i class="fa fa-edit"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-print"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-envelope"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fa fa-download"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fas fa-ban"></i> </a>
                  </li>
                </ul>
              </div>

              <div class="w-90">
                <ul class="list-group list-group--borderless list-group--compact">
                  <li class="list-group-item">
                    Invoice number: {{ popup_data.invoice_number }}
                  </li>
                  <li class="list-group-item">
                    Invoice date: {{ popup_data.invoice_date }}
                  </li>
                  <li class="list-group-item">
                    Vendor name: {{ popup_data.business_name }}
                  </li>
                  <li class="list-group-item">Vendor email: {{ popup_data.email }}</li>
                  <li class="list-group-item">Name: {{ popup_data.name }}</li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span
                      >Amount Gross: {{ popup_data.currency_symbol }}
                      {{ popup_data.total_amount_gross }}</span
                    >
                  </li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span
                      >Amount Paid: {{ popup_data.currency_symbol }}
                      {{ popup_data.total_amount_paid }}</span
                    >
                  </li>
                </ul>
              </div>

              <div class="card card--curve-05rem mt-3">
                <div class="card-body">
                  <p class="m-0">Note:</p>
                  <p class="m-0">
                    {{ popup_data.notes }}
                  </p>
                </div>
              </div>

              <div
                class="card card--curve-05rem mt-3 w-100 align-items-center justify-content-center"
              >
                <div class="w-auto p-2">
                  <div
                    v-for="(images_invoice, key) in popup_data.invoice_attachments"
                    v-bind:key="'inv_img' + key"
                  >
                    <img :src="images_invoice.url" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InvoiceComponent",

  data() {
    return {
      loadingInstance: null,
      show_total_expense: 0,
      errorMessage: "",
      invoice_server_data: [],
      form: {
        search_field: "",
        start_date: "",
        category: "all",
        end_date: new Date().toISOString().split("T")[0],
      },
      popup_data: {
        invoice_number: "",
        total_amount_gross: "",
        total_amount_paid: "",
        invoice_attachments: "",
        business_name: "",
        email: "",
        name: "",
        notes: "",
        currency_symbol: "",
        invoice_date: "",
      },
      invoice_sorting_desc: false,
    };
  },
  created() {},
  computed: {},
  mounted() {
    // Set start date to six months before
    const currentDate = new Date();
    const sixMonthsBefore = new Date(currentDate);
    sixMonthsBefore.setMonth(currentDate.getMonth() - 6);
    this.form.start_date = sixMonthsBefore.toISOString().split("T")[0];
    this.getData();
  },
  methods: {
    showPdf(invoiceData) {
      localStorage.setItem("invoice-details", JSON.stringify(invoiceData));
      this.$router.push({ name: "show-invoice" });
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    handleCommand(command) {
      if (command.action === "edit") {
        // Navigate to the edit page
        this.$router.push(`/edit-invoice/${command.id}`);
      }
    },
    validateForm() {
      if (!this.form.start_date) {
        this.errorMessage = "Start date is required";
        return false;
      }
      if (!this.form.end_date) {
        this.errorMessage = "End date is required";
        return false;
      }
      // Additional validations can be added here, such as date format checks
      return true;
    },
    async searchData() {
      this.showLoading();
      const data = {
        from_date: this.form.start_date,
        to_date: this.form.end_date,
        ...(this.form.search_field ? { search_field: this.form.search_field } : {}),
        category: this.form.category,
      };
      if (this.validateForm()) {
        await this.$store
          .dispatch("invoice/searchList", data)
          .then((res) => {
            if (res.data.status == 1) {
              this.invoice_server_data = res.data.response;
            } else {
              this.$toast.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            // this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
      } else {
        this.hideLoading();
      }
    },
    showDetails(id) {
      this.$store
        .dispatch("invoice/getInvoiceByNumber", { invoice_number: id })
        .then((res) => {
          if (res.data.status == 1) {
            this.popup_data.invoice_number = res.data.response.invoice_number;
            this.popup_data.invoice_date = res.data.response.invoice_date;
            this.popup_data.total_amount_gross = res.data.response.total_amount_gross;
            this.popup_data.total_amount_paid = res.data.response.total_amount_paid;
            this.popup_data.invoice_attachments = res.data.response.invoice_attachments;
            this.popup_data.business_name = res.data.response.client.business_name;
            this.popup_data.email = res.data.response.client.email;
            this.popup_data.name = res.data.response.client.name;
            this.popup_data.notes = res.data.response.client.notes;
            this.popup_data.currency_symbol = res.data.response.currency.symbol;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("invoice/getList", this.form)
        .then((res) => {
          if (res.data.status == 1) {
            this.invoice_server_data = res.data.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$toast.error("Resource not found");
          }
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteData(invoiceId, client_id) {
      this.showLoading();
      this.$store
        .dispatch("invoice/deleteInvoice", {
          invoice_id: invoiceId,
          client_id: client_id,
        })
        .then((res) => {
          //   console.log(res);
          if (res.status == 1) {
            this.$toast.success("Delete Successful.");
            this.$nextTick(() => {
              this.getData();
            });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    voidData(invoiceId) {
      this.showLoading();
      this.$store
        .dispatch("invoice/voidInvoice", { invoice_id: invoiceId })
        .then((res) => {
          //   console.log(res);
          if (res.status == 1) {
            this.$toast.success("Void Successful.");
            this.$nextTick(() => {
              this.getData();
            });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    isNotExpired(expiryDateFromLoop) {
      const expiryDate = new Date(expiryDateFromLoop);
      const today = new Date();

      // Compare expiryDate with today's date
      return expiryDate < today;
    },
    changeOrder() {
      if (this.invoice_sorting_desc) {
        // Sort data in descending order
        this.invoice_server_data.sort(
          (a, b) => new Date(b.invoice_date) - new Date(a.invoice_date)
        );
      } else {
        // Sort data in ascending order
        this.invoice_server_data.sort(
          (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date)
        );
      }
    },
  },
};
</script>

<style scoped></style>
